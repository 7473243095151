var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(this.selected_company.last_working_date !== null)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-2 ml-md-15 mr-15"},[_c('v-tabs',{staticClass:"tab-bar-custom"},[_c('v-tab',{on:{"change":function($event){return _vm.changeTab('report')}}},[_vm._v("Reports")]),(
            _vm.selectedCompanyUserStatus != 'employee' &&
            _vm.selectedCompanyReportStatus
          )?_c('v-tab',{on:{"change":function($event){return _vm.changeTab('intelligence-report')}}},[_vm._v("Intelligence Reports")]):_vm._e(),(
            _vm.selectedCompanyUserStatus != 'employee' &&
            _vm.selectedCompanyReportStatus
          )?_c('v-tab',{on:{"change":function($event){return _vm.changeTab('TimesheetView')}}},[_vm._v("Timesheet")]):_vm._e(),(
            _vm.selectedCompanyUserStatus != 'employee' &&
            _vm.selectedCompanyReportStatus
          )?_c('v-tab',{on:{"change":function($event){return _vm.changeTab('AttendenceView')}}},[_vm._v("Attendance")]):_vm._e(),(
            _vm.selectedCompanyUserStatus != 'employee' &&
            _vm.FaceRegistrationAllowed &&
            _vm.selectedCompanyReportStatus
          )?_c('v-tab',{on:{"change":function($event){return _vm.changeTab('FacialAttendence')}}},[_vm._v("Facial Attendance")]):_vm._e()],1)],1),_c(_vm.currentTabComponent,{tag:"component"})],1):_vm._e(),(
      _vm.selected_company.last_working_date == null &&
      _vm.selectedCompanyUserStatus !== 'employee'
    )?_c('div',[_c('DummyReports')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }