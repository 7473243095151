<!-- System: STA
    Purpose: This compoment will display reports page
              tabs to switch between them.
-->
<template>
  <v-app>
    <v-container fluid v-if="this.selected_company.last_working_date !== null">
      <v-row class="mt-2 ml-md-15 mr-15">
        <!-- Tabs Of Repots Page -->
        <v-tabs class="tab-bar-custom">
          <v-tab @change="changeTab('report')">Reports</v-tab>
          <v-tab
            @change="changeTab('intelligence-report')"
            v-if="
              selectedCompanyUserStatus != 'employee' &&
              selectedCompanyReportStatus
            "
            >Intelligence Reports</v-tab
          >
          <v-tab
            @change="changeTab('TimesheetView')"
            v-if="
              selectedCompanyUserStatus != 'employee' &&
              selectedCompanyReportStatus
            "
            >Timesheet</v-tab
          >
          <v-tab
            @change="changeTab('AttendenceView')"
            v-if="
              selectedCompanyUserStatus != 'employee' &&
              selectedCompanyReportStatus
            "
            >Attendance</v-tab
          >
          <v-tab
            @change="changeTab('FacialAttendence')"
            v-if="
              selectedCompanyUserStatus != 'employee' &&
              FaceRegistrationAllowed &&
              selectedCompanyReportStatus
            "
            >Facial Attendance</v-tab
          >
        </v-tabs>
      </v-row>
      <!-- Render Current Tab Component -->
      <component :is="currentTabComponent"></component>
    </v-container>
    <!-- Dummy Page For Reports If Last Working Date is nulls -->
    <div
      v-if="
        selected_company.last_working_date == null &&
        selectedCompanyUserStatus !== 'employee'
      "
    >
      <DummyReports />
    </div>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    report: () => import("@/components/reports/report"),
    AttendenceView: () => import("@/components/reports/AttendenceView"),
    IntelligenceReport: () => import("@/components/reports/IntelligentReport"),
    TimesheetView: () => import("@/components/reports/TimesheetView"),
    FacialAttendence: () => import("@/components/reports/FacialAttendence"),
    DummyReports: () => import("@/views/DummyPages/DummyReports"),
  },
  computed: {
    ...mapState("custom", [
      "reports",
      "selected_company",
      "companies_list",
      "team_members",
      "active_team_members_list",
    ]),
    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    selectedCompanyReportStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) {
        if (company.user_status == "employer") {
          return true;
        } else {
          return company.permission.reports;
        }
      } else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    showPermission() {
      let permission = this.$store.state.custom.selected_company.permission;
      if (this.selectedCompanyUserStatus === "secondary_user") {
        if (permission.work_diary) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    FaceRegistrationAllowed() {
      const company = this.$store.state.custom.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.facial_attendance_allowed;
      else {
        return false;
      }
    },
  },
  data() {
    return {
      currentTabComponent: "report",
    };
  },
  methods: {
    /**
     * This function is resposible for
     * changing the tab
     */
    changeTab(tab) {
      this.currentTabComponent = tab;
    },
  },
};
</script>


<style>
.tab-bar-custom .v-slide-group__content.v-tabs-bar__content {
  background-color: #fafafa;
}
</style>
